.MuiButtonBase-root.MuiListItem-root {
  padding-left: 2.12298682284041vw;
}

.imagemLogo {
  width: 5rem;
  height: 5rem;
  /* background: url('../../../assets/Images/Hbi/LogoFooter.svg') no-repeat center center; */
  background-size: contain;
}

.userBalanceValue {
  display: flex;
  align-items: center;
}

.userBalanceValue > h5 {
  width: 100%;
  font-weight: 500;
}

.userBalanceValue > h4 {
  width: 100%;
  font-size: 0.972vw;
  font-weight: 400;
  padding-right: 0.2vw;
}
